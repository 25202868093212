var site = site || {};
(function($) {
  Drupal.behaviors.mpp = {
    attach: function(context) {
      //insert touts between the mpp product grids.
      var $listSelector;
      var $products = $('.mpp__product', context);
      var productsArray = page_data['custom-mpp'].products;
      var qs = getUrlVars()['qs'];
      var $toutWrapper = $('.js-mpp_tout', context);
      var $this;
      var inlinePosition = 0;
      var $tout;
      $toutWrapper.each(function(i) {
        $this = $(this);
        inlinePosition = $this.data('position') - 1;
        //Check whether content block exists
        if ($this.find('.js-product-grid__tout').length > 0) {
          if (!isNaN(inlinePosition)) {
            // Creating a li dom and moving the existing dom so that it does not break its existing js events.
            $tout = $('<li/>').attr({
              'class': 'mpp__product product-grid-custom__tout',
              'data-position': inlinePosition,
            }).append($this.children());
            $listSelector = $products.eq(inlinePosition - i);
            $tout.insertBefore($listSelector);
            var $img = $tout.find('img:visible');
            $img.load(function() {
              $(this).addClass('fadeElemIn');
            });
          }
        }
      });

      $('.js-product-brief', context).each(function() {
        var productId = $(this).attr('data-product-id');
        var productData = _.find(productsArray, function(p) {
          return p.PRODUCT_ID === productId;
        });
        site.product.view.brief({
          containerNode: this,
          productData: productData
        });
      });

      site.product.view.miscFlagAlign($products);
      site.product.view.equalRowHeight($products);

      //quickshop test
      function getUrlVars() {
        var vars = [], hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < hashes.length; i++) {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
        }
        return vars;
      }
      if (qs) {
        $('.qs_test').removeClass('hidden');
        $('.qs_test').click(function(event) {
          var pid = $(this).attr('data-product-id');
          var quickshopData = _.find(productsArray, function(p) {
            return p.PRODUCT_ID === pid;
          });
          site.quickshop(quickshopData);
        });
      //$('.qs_test').eq(1).trigger('click');
      }

      // Procuct Sort
      site.productSort.init(productsArray);
      // Product compare nav
      site.mppCompareInit(productsArray);

      $('.product_brief__buttons-container').each(function() {
        if ($(this).has('.product_brief__reviews').length === 0) {
          $(this).find('.product_brief__desc1').after('<div class="product_brief__reviews-spacer"></div>');
        }
      });
    }
  };
})(jQuery);
